body,
html,
#root {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  font-family: Roboto, serif;
  box-sizing: border-box;
}

.token {
  font-family:"Courier New", monospace !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}


code {
  font-family:"Courier New", monospace !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

li {
  list-style-position: inside;
}

@keyframes show {
  from { opacity: 0; }
  to { opacity: 1; }
}

.ant-tree-node-selected path {
  fill: white;
}
